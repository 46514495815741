<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="productModal"
    :title="$t(type)"
    :buttons-hidden="true" autocomplete="nofill">
    <div v-if="!isCameraOpen" class="grid grid-cols-3 gap-4 mt-6 items-center">
      <div :class="isAndroid ? 'col-span-3'  : 'col-span-2'">
        <input type="file" @change="changeImage" class="hidden" ref="fileInput" accept="image/*">
        <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="px-4 py-6 cursor-pointer text-base upload-photo text-center">
          <div class="text-sm font-semibold">
            <div>
              <feather-icon icon="UploadCloudIcon" svgClasses="h-8 w-8 text-blue-100"/>
              <span class="font-semibold text-primary mx-1" @click.stop="$refs.fileInput.click()">{{$t('Click To Upload')}}</span>
              <span class="text-grey">{{$t('or drag and drop')}}</span> 
            </div>
            <span class="text-grey">{{$t('SVG, PNG, JPG or GIF (max. 800*400px - 1MP)')}}</span>
          </div>
          <img v-if="imageShow" class="max-h-full max-w-full rounded mt-4" :src="imageShow" alt="">
        </div>
      </div>
      <div class="col-span-1" :class="imageShow ? 'h-28' : 'h-full'" v-if="!isAndroid">
        <div class="cursor-pointer text-center py-6 text-base upload-photo h-full" @click="toggleCamera">
          <feather-icon icon="CameraIcon" svgClasses="h-8 w-8 text-blue-100"/>
          <p class="font-semibold text-grey text-sm">{{$t('Take Photo')}}</p>
        </div>
      </div>
    </div>    
    <div v-else class="w-full web-camera-container text-center border p-1">
      <div class="my-4">
        <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>
        <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
      </div>
      <div class="flex justify-around">
        <p class="material-icons-round text-white bg-blue hover:shadow-drop hover:bg-white hover:text-blue-100 cursor-pointer rounded-full p-2 text-3xl" @click="toggleCamera">close</p>
        <p class="material-icons-outlined text-white bg-blue hover:shadow-drop hover:bg-white hover:text-blue-100 cursor-pointer rounded-full p-2 text-3xl" @click="takePhoto">photo_camera</p>
      </div>
    </div>
    <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-1">
      <div class="col-span-1">
        <vs-input v-validate="'required'" :label-placeholder="$t('Product Name') + '*'" name="product name" v-model="product.name" :class="product.name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('product name')">{{ errors.first('product name') }}</span>
      </div>
      <div class="col-span-1 relative">
        <vs-input :disabled="type==='Edit Product'" v-validate="'required'" :label-placeholder="$t('SKU Code') + '*'" name="sku code" v-model="sku" :class="sku ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('sku code')">{{ errors.first('sku code') }}</span>
        <button :disabled="type==='Edit Product'" @click="generateSKU()" :class="type==='Edit Product' ? 'disable-generate' : 'enable-generate'" class="generator-button">{{ $t('Generate') }}</button>
      </div>
    </div>
    <div class="w-full">
      <vs-input :label-placeholder="$t('Description')" name="description" v-model="product.description" :class="product.description ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
      <span class="text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>
    </div>
    <div class="grid sm:grid-cols-6 grid-cols-3 gap-4 sm:mt-2">
      <div class="col-span-1 relative">
        <div class="sm:absolute sm:top-0 sm:mt-0 w-full">
          <p class="text-xs text-lightgray ml-1 my-2">{{$t('Color') + '*'}}</p>
          <input type="color" v-validate="'required'" name="color" v-model="product.color" class="w-full" />
        </div>
        <span class="text-danger text-sm" v-show="errors.has('color')">{{ errors.first('color') }}</span>
      </div>
      <div class="col-span-1">
        <vs-input v-validate="'required|decimal'" :label-placeholder="$t('Weight (kg)') + '*'" name="weight" v-model="product.weight" :class="product.weight ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('weight')">{{ errors.first('weight') }}</span>
      </div>
      <div class="col-span-1">
        <vs-input v-validate="'required|decimal'" :label-placeholder="$t('Length (cm)') + '*'" name="length" v-model="product.d_length" :class="product.d_length ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('length')">{{ errors.first('length') }}</span>
      </div>
      <div class="col-span-1">
        <vs-input v-validate="'required|decimal'" :label-placeholder="$t('Width (cm)') + '*'" name="width" v-model="product.d_width" :class="product.d_width ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('width')">{{ errors.first('width') }}</span>
      </div>
      <div class="col-span-1">
        <vs-input v-validate="'required|decimal'" :label-placeholder="$t('Height (cm)') + '*'" name="height" v-model="product.d_height" :class="product.d_height ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('height')">{{ errors.first('height') }}</span>
      </div>
      <div class="col-span-1">
        <vs-input :label-placeholder="$t('Size')" name="size" v-model="product.size" :class="product.size ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('size')">{{ errors.first('size') }}</span>
      </div>
    </div>
    <div class="w-full mt-5">
      <v-select :class="product.flags ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
        multiple autocomplete="nofill" label="name" name="flags" v-model="product.flags" :placeholder="$t('Flags')" :options="productFlags"/>
    </div>
    <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-1 sm:mt-2">
      <div class="col-span-1">
        <vs-input :label-placeholder="$t('Supplier')" name="supplier" v-model="product.supplier" :class="product.supplier ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
        <span class="text-danger text-sm" v-show="errors.has('supplier')">{{ errors.first('supplier') }}</span>
      </div>
      <div class="col-span-1 relative">
        <div class="sm:absolute sm:bottom-0 w-full">
          <vs-input v-validate="'numeric'" :label-placeholder="$t('Reorder count')" name="reorder_count" v-model="product.reorder_count" :class="product.reorder_count ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
          <span class="text-danger text-sm" v-show="errors.has('reorder_count')">{{ errors.first('reorder_count') }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button v-if="type === 'New Product'" @click="addNewProduct" class="active-btn btn">{{ $t('Add') }}</button>
      <button v-else @click="updateProduct" class="active-btn btn">{{ $t('Save') }}</button>
    </div>
  </shipblu-prompt> 
</template>

<script>

import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'
import {sendFulfillmentRequest} from '../../../../http/axios/requestHelper.js'
import i18nData from '../../../../i18n/i18nData.js'
import vSelect from 'vue-select'
import common from '@/assets/utils/common'

export default {
  props: ['productModal', 'type', 'product'],
  data () {
    return {
      productFlags: [],
      productChannels: [],
      productGroup: [],
      merchantID: '',
      merchantName: '',
      sku: '',
      image: '',
      imageShow: '',
      fileUrl: '',
      isCameraOpen: false,
      isPhotoTaken: false,
      isAndroid: false
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  watch: {
    'product' () {
      if (this.type === 'Edit Product') {
        this.sku = this.product.sku
      }
      this.$validator.resume()
    },
    'productModal' (val) {
      if (val === true) {
        this.sku = this.product ? this.product.sku : ''
        this.loadProductFlags()
        this.loadProductChannels()
        this.loadProductGroup()
        this.isAndroidFunction()
        this.imageShow = this.product.image
      }
    }
  },
  methods: {  
    toggleCamera () {
      if (this.isCameraOpen) {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.stopCameraStream()
      } else {
        this.isCameraOpen = true
        this.createCameraElement()
      }
    },
    createCameraElement () {
      const constraints = (window.constraints = {
        audio: false,
        video: true
      })
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream
        })
    },
    stopCameraStream () {
      if (this.$refs.camera.srcObject) {
        const tracks = this.$refs.camera.srcObject.getTracks()
        tracks.forEach(track => {
          track.stop()
        })
      }
      this.isCameraOpen = false
      this.isPhotoTaken = false
    },
    takePhoto () {
      if (this.$refs.camera.srcObject !== null) {
        const context = this.$refs.canvas.getContext('2d')
        context.drawImage(this.$refs.camera, 0, 0, 450, 337.5)
        this.downloadImage()
      } else {
        this.$vs.notify({
          color:'warning',
          title: i18nData[this.$i18n.locale]['Warning'],
          text: i18nData[this.$i18n.locale]['Please open the camera permission'],
          position: 'top-center'
        })
      }
    },
    downloadImage () {
      this.stopCameraStream()
      this.image = document.getElementById('photoTaken').toDataURL('image/jpeg')
      this.imageShow = this.image
      this.image = this.base64ImageToBlob(this.image)
      this.changeImage('fromCam')
    },
    base64ImageToBlob (str) {
      const pos = str.indexOf(';base64,')
      const type = str.substring(5, pos)
      const b64 = str.substr(pos + 8)
      const imageContent = atob(b64)
      const buffer = new ArrayBuffer(imageContent.length)
      const view = new Uint8Array(buffer)
      for (let n = 0; n < imageContent.length; n++) {
        view[n] = imageContent.charCodeAt(n)
      }
      const blob = new Blob([buffer], { type })
      return blob
    },
    changeImage (type) {
      const formData = new FormData()
      if (type === 'fromCam') {
        formData.append('file', this.image)
      } else {
        const [file] = this.$refs.fileInput.files
        if (!this.isImage(file)) {
          this.$vs.notify({
            title: 'Failed',
            position: 'top-center',
            text: 'Only supports upload .png, .jpg suffix files',
            color: 'warning'
          })
          return false
        }
        if (file) {
          this.image = URL.createObjectURL(file)
          this.imageShow = this.image
        }
        formData.append('file', this.$refs.fileInput.files[0])
      }
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/upload-file/', 'post', formData, true,
        (response) => {
          this.fileUrl = response.data.file_url
        }
      )
    },
    closeModal () {
      this.imageShow = ''
      this.$validator.pause()
      this.$emit('productModal', false)
      this.$emit('loadProductCatalog')
      this.sku = ''
      this.fileUrl = ''
      if (this.isCameraOpen) this.stopCameraStream()
    },
    loadProductFlags () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/flags/', 'get', null, true,
        (response) => {
          this.productFlags = response.data.results
        }
      )
    },
    loadProductChannels () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/channels/', 'get', null, true,
        (response) => {
          this.productChannels = response.data.results
        }
      )
    },
    loadProductGroup () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/product-groups/?merchant=${this.merchantID}`, 'get', null, true,
        (response) => {
          this.productGroup = response.data.results
        }
      )
    },
    addNewProduct () {
      this.$validator.validateAll().then(result => {
        if (result && this.product) {
          const productObj = {
            ...this.product,
            sku: this.sku,
            flags: this.product.flags ? this.product.flags.map(item => item.id) : [],
            group: 1
          }
          if (this.fileUrl) {
            productObj.image = this.fileUrl
          }
          sendFulfillmentRequest(false, false, this, 'api/v1/fc/products/', 'post', productObj, true,
            (response) => {
              this.$emit('updateUROProductStatus', response.data.id)
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Product'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.closeModal()
            }
          )
        }
      })
    },
    isAndroidFunction () {
      if (/(android)/i.test(navigator.userAgent)) {
        this.isAndroid = true
      } else {
        this.isAndroid = false
      }
    },
    updateProduct () {
      this.$validator.validateAll().then(result => {
        if (result && this.product) {
          const productObj = {
            name: this.product.name,
            sku: this.sku,
            color: this.product.color,
            weight: this.product.weight,
            d_length: this.product.d_length,
            d_width: this.product.d_width,
            d_height: this.product.d_height,
            supplier: this.product.supplier,
            size: this.product.size,
            description: this.product.description,
            reorder_count: this.product.reorder_count,
            flags: this.product.flags ? this.product.flags.map(item => item.id) : [],
            group: 1
          }
          if (this.fileUrl) {
            productObj.image = this.fileUrl
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/products/${this.product.id}/`, 'patch', productObj, true,
            () => {
              this.closeModal()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Product'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      })
    },
    generateSKU () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/inventory/sku-generator/?merchant_name=${this.merchantName}`, 'get', null, true,
        (response) => {
          this.sku = response.data.sku
        })
    },
    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only support uploading one file!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isImage(rawFile)) {
        this.$vs.notify({
          title: 'Login Attempt',
          text: 'Only supports upload .png, .jpg suffix files',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return false
      }
    },
    isImage (file) {
      if (file) {
        return /\.(png|jpg)$/.test(file.name)
      }
    }
  },
  created () {
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let user = {}
      merchantData.then(results => {
        user = results.merchantData
        this.merchantID = user.id
        this.merchantName = user.name
      })
    }
  }
}
</script>

<style lang="scss">
.text-lightgray {
  color: rgb(133, 133, 133);
}
.generator-button {
  position: absolute;
  white-space: nowrap;
  font-size: 0.75rem;
  right: 0.65rem;
  top: 2rem;
  border: none;
  color: #fff;
  padding: 2px 4px;
  border-radius: 2px;
}
[dir=rtl] .generator-button {
  top: 2.25rem;
}
.enable-generate {
  background: #1c5bfe;
  cursor: pointer;
}
.disable-generate {
  background: #8eadfd;
  cursor: auto;
}
.upload-photo {
  background: rgba(28, 91, 254, 0.08);
  border-radius: 14px !important;
  border: 2px dashed rgba(28, 91, 254, 0.26) !important;
}
.hide {
  display: none;
}
.border{
  border: 1px solid #ccc;
}
.bg-blue{
  background: #1c5bfe;
}
</style>